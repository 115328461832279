<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="jobGeo">
      <template #[`item.createTime`]="{ item }">
        {{ item.createTime | formatDate }}
      </template>
      <template #[`item.activated`]="{ item }">
        <!-- <v-btn>
          <v-icon small
                  :style="{ color: item.activated === true ? '#689F38' : '#E8EAF6' }">
            mdi-checkbox-marked-outline
          </v-icon>
        </v-btn> -->
        <v-switch
          dense
          v-model="item.activated"
          inset
          @change="changeStatus(item._id)"></v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'jobGeofenceList',
  props: {
    jobGeo: {
      type: Array
    }
  },
  data() {
    return {
      openFormUpdateSound: false,
      editedIndex: -1,
      headers: [
        {
          text: 'Job Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Title',
          align: 'start',
          value: 'titleEn'
        },
        {
          text: 'Create Date',
          align: 'center',
          sortable: true,
          value: 'createTime'
        },
        {
          text: 'Job Status',
          align: 'center',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Job Activated',
          align: 'center',
          value: 'activated'
        },
        // {
        //   align: 'start',
        //   sortable: true,
        //   value: 'action'
        // },
      ],
      cancelJob: {
        id: '',
        reason: '',
      },
    };
  },
  methods: {
    async changeStatus(jobId) {
      this.cancelJob.id = jobId;
      this.cancelJob.reason = 'Disable job.'

      await this.$store.dispatch('jobManagement/DELETE_JOB_WELCOME_BY_ID', this.cancelJob);
    }
  }
}
</script>

<style>

</style>